import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Input } from './ui/Input';
import { Button } from './ui/Button'; // Adjust the path
import { BarChart2, Droplet, Wheat } from 'lucide-react';

// Typewriter hook
const useTypewriter = (text, speed = 100) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < text.length) {
        setDisplayText((prev) => prev + text.charAt(i));
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, speed);

    return () => clearInterval(typingInterval);
  }, [text, speed]);

  return displayText;
};

// Countdown hook
const useCountdown = (targetDate) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const difference = targetDate.getTime() - now.getTime();

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        });
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return timeLeft;
};

// Coming Soon component
export default function ComingSoon() {
  const typewriterText = useTypewriter('C oming Soon');
  const countdown = useCountdown(new Date('2023-12-31')); // Set your target date

  return (
    <div className="relative z-10 text-center space-y-8 p-8 bg-black bg-opacity-30 rounded-xl backdrop-blur-md min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-900 via-blue-700 to-blue-500 text-white overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute left-0 top-0 w-full h-full">
          {[...Array(20)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute bg-white bg-opacity-10 rounded-full"
              style={{
                width: Math.random() * 300 + 50,
                height: Math.random() * 300 + 50,
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
              }}
              animate={{
                x: [0, Math.random() * 100 - 50],
                y: [0, Math.random() * 100 - 50],
              }}
              transition={{
                duration: Math.random() * 10 + 10,
                repeat: Infinity,
                repeatType: 'reverse',
              }}
            />
          ))}
        </div>
      </div>
      <div className="relative z-10 text-center space-y-8 p-8 bg-black bg-opacity-30 rounded-xl backdrop-blur-md">
        <h1 className="text-5xl font-bold tracking-tight">
          {typewriterText}
          <span className="animate-pulse">|</span>
        </h1>
        <p className="text-xl max-w-2xl mx-auto">
          Our Commodities Info page is under construction. Stay tuned for comprehensive insights into global markets, trends, and analysis.
        </p>
        <div className="flex justify-center space-x-8">
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 5, repeat: Infinity, ease: 'linear' }}
          >
            <BarChart2 size={48} />
          </motion.div>
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 7, repeat: Infinity, ease: 'linear' }}
          >
            <Droplet size={48} />
          </motion.div>
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 6, repeat: Infinity, ease: 'linear' }}
          >
            <Wheat size={48} />
          </motion.div>
        </div>
        <div className="flex justify-center space-x-4">
          {Object.entries(countdown).map(([unit, value]) => (
            <div key={unit} className="text-center">
              <div className="text-4xl font-bold">{value}</div>
              <div className="text-sm uppercase">{unit}</div>
            </div>
          ))}
        </div>
        <form className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <Input
            type="email"
            placeholder="Enter your email"
            className="max-w-xs bg-white bg-opacity-20 border-white border-opacity-30 text-white placeholder-gray-300"
          />
          <Button
            type="submit"
            className="bg-white bg-opacity-20 hover:bg-opacity-30 border-white border-opacity-30"
          >
            Notify Me
          </Button>
        </form>
      </div>
    </div>
  );
}

