import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import AdminDashboard from './pages/AdminDashboard';
import AdminDashboardProtected from './pages/AdminDashboardProtected';
import UserDashboard from './pages/UserDashboard';
import UserNewsDashboard from './pages/UserNewsDashboard';
import { AdminProvider } from './context/AdminContext'; // Ensure the path is correct
import UserCommodities from './pages/UserCommodities';
import './i18n';

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Navigate to="/user" />} />
      <Route path="/user/news" element={<UserNewsDashboard />} />
      <Route path="/user/commodities" element={<UserCommodities />} />
      <Route
        path="/login"
        element={
          <AdminProvider>
            <Login />
          </AdminProvider>
        }
      />
      <Route
        path="/register"
        element={
          <AdminProvider>
            <Register />
          </AdminProvider>
        }
      />
      <Route
        path="/admin"
        element={
          <AdminProvider>
            <AdminDashboard />
          </AdminProvider>
        }
      />
      <Route
        path="/protected"
        element={
          <AdminProvider>
            <AdminDashboardProtected />
          </AdminProvider>
        }
      />
      <Route path="/user" element={<UserDashboard />} />
    </Routes>
  </Router>
);

export default App;

