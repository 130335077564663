import { useState, useEffect } from 'react';
import axios from 'axios';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Initial value set to null
  const [isLoading, setIsLoading] = useState(true);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = localStorage.getItem('exrToken');

      if (token) {
        try {
          const response = await axios.get(`${API_BASE_URL}/admin/me`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          console.log('API response status:', response.status);

          if (response.status === 200) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
            localStorage.removeItem('exrToken');
          }
        } catch (error) {
          setIsAuthenticated(false);
          localStorage.removeItem('exrToken');
        }
      } else {
        setIsAuthenticated(false);
      }

      setIsLoading(false);
    };

    checkAuthentication();
  }, []); // Empty dependency array means this effect runs once after the initial render

  return { isAuthenticated, isLoading };
};

export default useAuth;

