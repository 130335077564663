import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddArticleForm = ({ onClose }) => {
  // Define state for each form field
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [content, setContent] = useState('');
  const [source, setSource] = useState('');
  const [datePublished, setDatePublished] = useState(new Date().toISOString().split('T')[0]);
  const [articleCategoryId, setArticleCategoryId] = useState(''); // New state for category
  const [categories, setCategories] = useState([]); // State to store fetched categories
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Fetch article categories from the backend
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/admin/news_categ`);
        setCategories(response.data); // Store categories in state
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('exrToken');
      // Create the request body
      const requestBody = {
        title,
        sub_title: subTitle,
        content,
        source,
        date_published: datePublished,
        article_category_id: articleCategoryId, // Include the category ID in the request body
      };

      // Send the POST request with the request body
      await axios.post(
        'http://127.0.0.1:8000/admin/news',
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onClose(); // Close the modal after successful submission
    } catch (error) {
      console.error('Error adding article:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="block text-gray-700">Title</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full p-2 border rounded"
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Sub Title</label>
        <input
          type="text"
          value={subTitle}
          onChange={(e) => setSubTitle(e.target.value)}
          className="w-full p-2 border rounded"
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Content</label>
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className="w-full p-2 border rounded"
          required
        ></textarea>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Source</label>
        <input
          type="text"
          value={source}
          onChange={(e) => setSource(e.target.value)}
          className="w-full p-2 border rounded"
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Date Published</label>
        <input
          type="date"
          value={datePublished}
          onChange={(e) => setDatePublished(e.target.value)}
          className="w-full p-2 border rounded"
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Category</label>
        <select
          value={articleCategoryId}
          onChange={(e) => setArticleCategoryId(e.target.value)}
          className="w-full p-2 border rounded"
          required
        >
          <option value="" disabled>Select a category</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.article_category_name}
            </option>
          ))}
        </select>
      </div>

      <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded-lg">
        Add Article
      </button>
    </form>
  );
};

export default AddArticleForm;

