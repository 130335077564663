import { useState, useEffect } from 'react';

export default function Ticker() {
  const [tickerData, setTickerData] = useState([
    { symbol: 'USD/EUR', value: '0.8412', change: '+0.05%' },
    { symbol: 'GBP/USD', value: '1.2563', change: '-0.12%' },
    { symbol: 'JPY/USD', value: '0.0093', change: '+0.02%' },
    { symbol: 'GOLD', value: '1892.50', change: '+0.34%' },
    { symbol: 'OIL', value: '75.21', change: '-0.89%' },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setTickerData(prevData =>
        prevData.map(item => ({
          ...item,
          value: (parseFloat(item.value) * (1 + (Math.random() - 0.5) * 0.002)).toFixed(4),
          change: ((Math.random() - 0.5) * 0.2).toFixed(2) + '%',
        }))
      );
    };

    const intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="whitespace-nowrap overflow-hidden bg-navy">
      <div className="inline-block animate-move text-white">
        {tickerData.concat(tickerData).map((item, index) => (
          <div key={index} className="inline-flex items-center mx-5">
            <span className="font-bold">{item.symbol}</span>
            <span className="mx-2">{item.value}</span>
            <span className={`ml-2 ${item.change.startsWith('-') ? 'text-red-500' : 'text-green-500'}`}>
              {item.change}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

