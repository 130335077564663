import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddBankModal from './AddBankModal';

const BanksList = () => {
  const [banks, setBanks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newBankName, setNewBankName] = useState('');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem('exrToken');

  // Fetch the list of banks from the backend
  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/admin/blist`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request headers
          },
        });
        setBanks(response.data); // Assuming the response contains an array of banks
      } catch (error) {
        console.error('Error fetching banks:', error);
      }
    };

    fetchBanks();
  }, [token]);

  // Handle bank deletion
  const handleDelete = async (bankId) => {
    try {
      await axios.delete(`${API_BASE_URL}/admin/blist?bank_id=${bankId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request headers
        },
      });
      setBanks(banks.filter((bank) => bank.id !== bankId));
    } catch (error) {
      console.error('Error deleting bank:', error);
    }
  };

  // Handle adding a new bank
  const handleAddBank = async () => {
    if (newBankName.trim()) {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/admin/add_banks`,
          {
            bank_name: newBankName,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request headers
            },
          }
        );

        setBanks([...banks, response.data]); // Assuming the response contains the newly added bank's details
        setNewBankName('');
        setShowModal(false);
      } catch (error) {
        console.error('Error adding bank:', error);
      }
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Banks List</h2>
      <ul>
        {banks.map((bank, index) => (
          <li key={index} className="flex justify-between items-center mb-2">
            <span>{bank.bank_name}</span>
            <button
              onClick={() => handleDelete(bank.id)}
              className="text-red-500 hover:text-red-700"
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
      <button
        onClick={() => setShowModal(true)}
        className="mt-4 bg-navy-800 text-white px-4 py-2 rounded hover:bg-navy-600"
      >
        Add Bank
      </button>

      {showModal && (
        <AddBankModal
          newBankName={newBankName}
          setNewBankName={setNewBankName}
          handleAddBank={handleAddBank}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
};

export default BanksList;

