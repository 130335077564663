// src/components/LiveTopTransaction.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";

const LiveTopTransaction = ({ title, endpoint, priceField }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {t, i18n} = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(endpoint); // Using the dynamic endpoint prop
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [endpoint]); // Re-fetch data if the endpoint prop changes

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="p-4 bg-white shadow-md rounded-lg">
      <h2 className="text-xl font-semibold mb-4">{title}</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b-2 border-gray-200">{t("bank")}</th>
              <th className="py-2 px-4 border-b-2 border-gray-200">{t("currency")}</th>
              <th className="py-2 px-4 border-b-2 border-gray-200">{t("price")}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="py-2 px-4 border-b border-gray-200">{item.bank_name}</td>
                <td className="py-2 px-4 border-b border-gray-200">
                  {item.currency_name} ({item.currency_icon})
                </td>
                <td className="py-2 px-4 border-b border-gray-200">
                  {item[priceField]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LiveTopTransaction;

