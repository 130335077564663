import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

i18next
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        resources: {
            en: {
                translation: {
                    title:"Ethiopian Live Forex Rates from Local Banks",
                    bank: "Bank",
                    currency:"Currency",
                    price:"Price",
                    title_tsp:"Top Transaction Selling Price",
                    title_tbp:"Top Transaction Buying Price",
                    title_csp:"Top Cash Selling Price",
                    title_cbp:"Top Cash Buying Price",
                    title_two:"Date",
                    tsp:"Transactional Selling Price",
                    tbp:"Transactional Buying",
                    csp:"Cash Selling",
                    cbp:"Cash Buying",
                    currency_selection:"Select Currency"
                }
            },
            am: {
                translation: {
                    title:"የኢትዮጲያ የውጨ ምንዛሬ ገበያ ከሁሉም ባንኮች በቀጥታ",
                    bank:"የባንክ ስም",
                    currency:"ገንዘብ",
                    price:"ዋጋ",
                    title_csp:"ከፍተኛ የገንዘብ መሸጫ ዋጋ",
                    title_cbp:"ከፍተኛ የገንዘብ መግዛ ዋጋ",
                    title_tbp:"ከፍተኛ የመገበያያ መግዣ ዋጋ",
                    title_tsp:"ከፍተኛ የመገበያያ መሸጫ ዋጋ",
                    title_two:"ቀን",
                    tsp:"የመገበያያ መሸጫ ዋጋ",
                    tbp:"የመገበያያ መግዣ ዋጋ",
                    csp:"የገንዘብ መሸጫ ዋጋ",
                    cbp:"የገንዘብ መግዣ ዋጋ",
                    currency_selection:"ምንዛሪ ይምረጡ"
                }
            },
            sm: {
                translation: {
                    title:"Bangiyada Itoobiya Sicirka sarifka tooska ah ee farahaaga",
                    bank:"Bangiyada",
                    currency:"Lacagta",
                    price:"Qiimaha",
                    title_tsp:"Sare wax kala iibsiga",
                    title_tbp:"Sare wax kala iibsiga.",
                    title_csp:"Sare iibinta lacagta caddaanka ah",
                    title_cbp:"Sare iibsashada lacag caddaan ah",
                    title_two:"Taariikhda",
                    tsp:"Wax kala iibsiga",
                    tbp:"Wax kala iibsiga",
                    csp:"iibinta lacagta caddaanka ah",
                    cbp:"Iibsashada lacag caddaan ah",
                    currency_selection:"Dooro Lacagta"
                }

                
            },
            afan: {
                translation: {
                    title:"Baankota Itoophiyaa Live Forex Rates Quba Keessanitti",
                    bank:"Baankota",
                    currency:"Maallaqa",
                    price:"Gatii",
                    title_tsp:"Gubbaa gurgurtaa daldalaa gurguruu",
                    title_tbp:"Gubbaa bittaa daldalaa bitachuu",
                    title_csp:"Gubbaa maallaqaan gurguruu",
                    title_cbp:"Gubbaa maallaqaan bitachuu.",
                    title_two:"Guyyaa",
                    tsp:"Gurgurtaa daldalaa gurguruu",
                    tbp:"Bittaa daldalaa bitachuu",
                    csp:"Maallaqaan gurguruu",
                    cbp:"Maallaqaan bitachuu",
                    currency_selection:"Maallaqa filadhu"

                }
            },
            tig: {
                translation: {
                    title:"ባንክታት ኢትዮጵያ ቀጥታዊ ዋጋታት ፎርክስ ኣብ ጫፍ ኣጻብዕትኹም",
                    bank:"ባንክ",
                    currency:"ገንዘብ",
                    price:"ዋጋ",
                    title_tsp:"ዋጋ መሸጣ ዝለዓለ ንግዲ",
                    title_tbp:"ዋጋ ምዕዳግ ዝለዓለ ንግዲ",
                    title_csp:"ዝለዓለ ዋጋ መሸጣ ገንዘብ",
                    title_cbp:"ዝለዓለ ዋጋ ምዕዳግ ገንዘብ",
                    title_two:"ዕለት",
                    tsp:"ብትራንዛክሽን ምሻጥ",
                    tbp:"ብትራንዛክሽን ምዕዳግ",
                    csp:"ብጥረ ገንዘብ ምሻጥ",
                    cbp:"ብጥረ ገንዘብ ምዕዳግ",
                    currency_selection:"ባጤራ ምረጽ"

                }
            }
        }
    });
