import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddExchangeModal from './AddExchangeModal';

const ExchangeRateList = () => {
  const [exchangeRates, setExchangeRates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [bankId, setBankId] = useState('');
  const [currencyId, setCurrencyId] = useState('');
  const [transactionalBuyingPrice, setTransactionalBuyingPrice] = useState('');
  const [transactionalSellingPrice, setTransactionalSellingPrice] = useState('');
  const [cashBuyingPrice, setCashBuyingPrice] = useState('');
  const [cashSellingPrice, setCashSellingPrice] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [filteredExchangeRates, setFilteredExchangeRates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


  const token = localStorage.getItem('exrToken');

  // Fetch the list of exchange rates from the backend
  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/admin/xlist`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setExchangeRates(response.data);
      } catch (error) {
        console.error('Error fetching exchange rates:', error);
      }
    };

    fetchExchangeRates();
  }, [token]);

  // Handle exchange rate deletion
  const handleDelete = async (rateId) => {
    try {
      await axios.delete(`${API_BASE_URL}/admin/x_rlist?x_id=${rateId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setExchangeRates(exchangeRates.filter((rate) => rate.id !== rateId));
    } catch (error) {
      console.error('Error deleting exchange rate:', error);
    }
  };

  // Handle adding a new exchange rate
const handleAddExchangeRate = async (bankId, currencyId, transactionalBuyingPrice, transactionalSellingPrice, cashBuyingPrice, cashSellingPrice) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/admin/add_xchange`,
      {
        bank_id: bankId,
        currency_id: currencyId,
        transactional_buying_price: transactionalBuyingPrice,
        transactional_selling_price: transactionalSellingPrice,
        cash_buying_price: cashBuyingPrice,
        cash_selling_price: cashSellingPrice,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Refetch the exchange rates to get updated data
    const updatedResponse = await axios.get(`${API_BASE_URL}/admin/xlist`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setExchangeRates(updatedResponse.data);
    setShowModal(false);
  } catch (error) {
    console.error('Error adding exchange rate:', error);
  }
};


  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Exchange Rate List</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bank Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Currency Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Transactional Buy</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Transactional Sell</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cash Buy</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cash Sell</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {exchangeRates.map((rate, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap">{rate.bank_name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{rate.currency_name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{rate.transactional_buying_price}</td>
                <td className="px-6 py-4 whitespace-nowrap">{rate.transactional_selling_price}</td>
                <td className="px-6 py-4 whitespace-nowrap">{rate.cash_buying_price}</td>
                <td className="px-6 py-4 whitespace-nowrap">{rate.cash_selling_price}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    onClick={() => handleDelete(rate.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        onClick={() => setShowModal(true)}
        className="mt-4 bg-navy-800 text-white px-4 py-2 rounded hover:bg-navy-600"
      >
        Add Exchange Rate
      </button>

      {showModal && (
        <AddExchangeModal
          token={token}  // Pass the token as a prop
          transactionalBuyingPrice={transactionalBuyingPrice}
          setTransactionalBuyingPrice={setTransactionalBuyingPrice}
          transactionalSellingPrice={transactionalSellingPrice}
          setTransactionalSellingPrice={setTransactionalSellingPrice}
          cashBuyingPrice={cashBuyingPrice}
          setCashBuyingPrice={setCashBuyingPrice}
          cashSellingPrice={cashSellingPrice}
          setCashSellingPrice={setCashSellingPrice}
          handleAddExchangeRate={handleAddExchangeRate}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
};

export default ExchangeRateList;

