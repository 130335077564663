import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ImageUpload from './ImageUpload';  // Assuming ImageUpload is in the same directory

const ImagePage = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem('exrToken');

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/admin/images`, {
            headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request headers
            }
        });
        setImages(response.data);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  const handleDelete = async (imageId) => {
    if (window.confirm('Are you sure you want to delete this image?')) {
      try {
        await axios.delete(`${API_BASE_URL}/admin/images`, { data: { image_id: imageId } });
        setImages(images.filter(image => image.id !== imageId));
      } catch (error) {
        console.error('Error deleting image:', error);
      }
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="p-4">
      <h1 className="text-3xl font-semibold mb-6">Admin Dashboard</h1>
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Manage Promotional Images</h2>
        <ImageUpload />
      </section>
      <section>
        <h2 className="text-2xl font-semibold mb-4">Uploaded Images</h2>
        <div className="grid grid-cols-3 gap-4">
          {images.map(image => (
            <div key={image.id} className="relative">
              <img
                src={image.url}
                alt={image.title}
                className="object-cover w-full h-48 cursor-pointer rounded-lg"
                onClick={() => handleImageClick(image)}
              />
              <button
                onClick={() => handleDelete(image.id)}
                className="absolute top-2 right-2 bg-red-600 text-white px-2 py-1 rounded hover:bg-red-800"
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      </section>

      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center"
          onClick={closeModal}
        >
          <div className="bg-white p-4 rounded-lg">
            <img
              src={selectedImage.url}
              alt={selectedImage.title}
              className="max-w-full max-h-full rounded-lg"
            />
            <button
              onClick={closeModal}
              className="mt-4 bg-navy-800 text-white px-4 py-2 rounded hover:bg-navy-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImagePage;

