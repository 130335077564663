import React, { useState } from 'react';
import UserHeader from '../components/UserHeader';
import NewsList from '../components/NewsList';
import NewsDetail from '../components/NewsDetail';

const UserNewsDashboard = () => {
  const [selectedArticleID, setSelectedArticleID] = useState(null);

  const handleNewsItemClick = (articleId) => {
    setSelectedArticleID(articleId);
    console.log(articleId);
  };

  return (
    <div>
      <UserHeader />
      {selectedArticleID ? (
        <NewsDetail article={selectedArticleID} />
      ) : (
        <NewsList onNewsItemClick={handleNewsItemClick} />
      )}
    </div>
  );
};

export default UserNewsDashboard;

