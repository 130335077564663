import React, { useState, useEffect } from 'react';
import { Card, CardContent } from "../components/CardComponents";
import { Clock, User, Tag } from 'lucide-react';
import axios from 'axios';
import { Link } from 'react-router-dom';  // React Router's Link

const NewsDetail = ({ article }) => {
  const [articleData, setArticleData] = useState(null);
  const [relatedNews, setRelatedNews] = useState([]);  // Added relatedNews state
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    const fetchArticleData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/user/news?article_id=${article}`);
        const data = response.data;
        setArticleData(data[0]);
      } catch (error) {
        console.error("Error fetching article data:", error);
      }
    };

    if (article) {
      fetchArticleData();
    }
  }, [article]);

  useEffect(() => {
    // Fetch related news (this is a placeholder; implement based on your backend)
    const fetchRelatedNews = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/user/related-news?category=${articleData?.article_category_id}`);
        setRelatedNews(response.data);
      } catch (error) {
        console.error("Error fetching related news:", error);
      }
    };

    if (articleData) {
      fetchRelatedNews();
    }
  }, [articleData]);

  if (!articleData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <Card className="border-t-4 border-navy-blue">
        <CardContent className="p-6">
          <div className="mb-4">
            <span className="inline-block bg-[#001f3f] text-white text-xs font-semibold px-2 py-1 rounded">
              {articleData.article_category_name}
            </span>
          </div>
          <h1 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4">{articleData.title || "Unknown Title"}</h1>
          <p className="text-xl text-gray-600 mb-6">{articleData.sub_title || "No Subtitle"}</p>
          <div className="flex justify-between items-center text-sm text-gray-500 mb-8">
            <div className="flex items-center">
              <User className="w-4 h-4 mr-2" />
              {articleData.author || "Unknown Author"}
            </div>
            <div className="flex items-center">
              <Clock className="w-4 h-4 mr-2" />
              {articleData.date_published || "Unknown Date"}
            </div>
          </div>
          <div className="prose max-w-none">{articleData.content || "No Content"}</div>
          <div className="mt-8 pt-4 border-t border-gray-200">
            <div className="flex items-center text-sm text-gray-500">
              <Tag className="w-4 h-4 mr-2" />
              Tags:
              <span className="ml-2 text-navy-blue hover:underline cursor-pointer">Economy</span>,
              <span className="ml-2 text-navy-blue hover:underline cursor-pointer">Trade</span>,
              <span className="ml-2 text-navy-blue hover:underline cursor-pointer">Global Summit</span>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Related News Section */}
      <div className="lg:col-span-1 space-y-8">
        <Card>
          <CardContent className="p-6">
            <h2 className="text-xl font-semibold text-[#001f3f] mb-4">Related News</h2>
            <ul className="space-y-4">
              {relatedNews.length > 0 ? (
                relatedNews.map((news) => (
                  <li key={news.id}>
                    <Link to={`/news/${news.id}`} className="block hover:bg-gray-50 p-2 rounded">
                      <h3 className="text-sm font-medium text-gray-900">{news.title}</h3>
                      <p className="text-xs text-gray-500 mt-1">{news.article_category_name}</p>
                    </Link>
                  </li>
                ))
              ) : (
                <p>No related news found.</p>
              )}
            </ul>
          </CardContent>
        </Card>

        <Card className="overflow-hidden">
          <CardContent className="p-6">
            <h2 className="text-xl font-semibold text-[#001f3f] mb-4">Advertisement</h2>
            <div className="bg-gray-200 h-64 flex items-center justify-center text-gray-500">
              Ad Space
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default NewsDetail;

