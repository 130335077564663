import React from 'react';

const AddBankModal = ({ newBankName, setNewBankName, handleAddBank, setShowModal }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold mb-4">Add a New Bank</h3>
        <input
          type="text"
          value={newBankName}
          onChange={(e) => setNewBankName(e.target.value)}
          placeholder="Enter bank name"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg mb-4"
        />
        <div className="flex justify-end">
          <button
            onClick={() => setShowModal(false)}
            className="mr-2 px-4 py-2 text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-100"
          >
            Cancel
          </button>
          <button
            onClick={handleAddBank}
            className="px-4 py-2 bg-navy-800 text-white rounded-lg hover:bg-navy-600"
          >
            Add Bank
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddBankModal;

