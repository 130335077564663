import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from './Modal'; // Import the Modal component
import AddArticleForm from './AddArticleForm'; // Import the AddArticleForm component
import AddArticleCategoryForm from './AddArticleCategoryForm';

const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage the modal
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem('exrToken');

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/admin/news`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setArticles(response.data);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, [token]);

  const handleOpenModal = () => {
    setIsModalOpen(true); // Open the modal when the button is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };
  const handleOpenCategoryModal = () => {
    setIsCategoryModalOpen(true); // Open the modal when the button is clicked
  };

  const handleCloseCategoryModal = () => {
    setIsCategoryModalOpen(false); // Close the modal
  };
  return (
    <div className="bg-white p-4 rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Existing Articles</h2>
        <button
          onClick={handleOpenModal} // Trigger modal opening
          className="bg-blue-600 text-white px-4 py-2 rounded-lg"
        >
          New Article +
        </button>
        <button
          onClick={handleOpenCategoryModal} // Trigger modal opening
          className="bg-blue-600 text-white px-4 py-2 rounded-lg"
        >
          New Category +
        </button>
      </div>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Title</th>
            <th className="py-2 px-4 border-b">Source</th>
            <th className="py-2 px-4 border-b">Date</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {articles && articles.length > 0 ? (
            articles.map((article, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">{article.title}</td>
                <td className="py-2 px-4 border-b">{article.source}</td>
                <td className="py-2 px-4 border-b">{article.date_published}</td>
                <td className="py-2 px-4 border-b">
                  <button className="text-blue-600 hover:underline mr-4">Edit</button>
                  <button className="text-red-600 hover:underline">Delete</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No articles available</td>
            </tr>
          )}
        </tbody>

      </table>

      {/* Modal for Add Article Form */}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <AddArticleForm onClose={handleCloseModal} /> {/* Pass onClose to close the modal after submission */}
      </Modal>
      {/* Modal for Add Article Category Form */}
      <Modal isOpen={isCategoryModalOpen} onClose={handleCloseCategoryModal}>
        <AddArticleCategoryForm onClose={handleCloseCategoryModal} />
      </Modal>
    </div>
  );
};

export default ArticleList;

