import React, { useState, useEffect } from 'react';
import NewsCard from './NewsCard';
import { Card, CardContent } from './CardComponents'
import axios from 'axios';

const NewsList = ({ onNewsItemClick }) => {
  const [newsList, setNewsList] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchNewsList = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/user/news_topics`);
        setNewsList(response.data);
      } catch (error) {
        console.error('Error fetching news list: ', error);
      }
    };
    fetchNewsList();
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-bold text-[#001f3f] mb-8">Latest News</h1>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {newsList.map((news) => (
          <NewsCard key={news.id} news={news} onClick={() => onNewsItemClick(news.id)} />
        ))}
      </div>
      <div className="mt-8 text-center">
        <button variant="outline" className="text-[#001f3f] border-[#001f3f] hover:bg-[#001f3f] hover:text-white">
          Load More News
        </button>
      </div>
      <Card className="overflow-hidden">
        <CardContent className="p-6">
          <h2 className="text-xl font-semibold text-[#001f3f] mb-4">Advertisement</h2>
          <div className="bg-gray-200 h-64 flex items-center justify-center text-gray-500">
            Ad Space
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardContent className="p-6">
          <h2 className="text-xl font-semibold text-[#001f3f] mb-4">Trending Topics</h2>
          <div className="flex flex-wrap gap-2">
            <button variant="outline" size="sm" className="text-[#001f3f] border-[#001f3f]">
              #Inflation
            </button>
            <button variant="outline" size="sm" className="text-[#001f3f] border-[#001f3f]">
              #StockMarket
            </button>
            <button variant="outline" size="sm" className="text-[#001f3f] border-[#001f3f]">
              #CapitalMarket
            </button>
            <button variant="outline" size="sm" className="text-[#001f3f] border-[#001f3f]">
              #OilNews
            </button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default NewsList;

