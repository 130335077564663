import React from 'react';
import { Card, CardContent } from './CardComponents';
import { Clock, User, TrendingUp, DollarSign, BarChart2 } from 'lucide-react';

const CategoryIcon = ({ category }: { category: string }) => {
  switch (category) {
    case 'finance':
      return <DollarSign className="w-5 h-5" />;
    // Add more cases for other categories
    default:
      return null;
  }
};

const NewsCard = ({ news, onClick }) => {
  return (
    <Card
      className="hover:shadow-lg transition-shadow duration-300 border-l-4 border-[#001f3f] cursor-pointer"
      onClick={onClick}
    >
      <CardContent className="p-4">
        <div className="flex items-center text-xs font-semibold text-[#001f3f] mb-2">
          <CategoryIcon category={news.article_category_name} />
          <span className="ml-2 font-semibold">{news.article_category_name}</span>
        </div>
        <h2 className="text-xl font-bold mb-2 text-gray-800">{news.title}</h2>
        <div className="flex justify-between items-center text-xs text-gray-500">
          <div className="flex items-center">
            <User className="w-4 h-4 mr-1" />
            {news.author || 'Unknown'}
          </div>
          <div className="flex items-center">
            <Clock className="w-4 h-4 mr-1" />
            {news.date_published}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default NewsCard;

