import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ImageTicker = () => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/user/ad`);
        setImages(response.data);
      } catch (err) {
        setError('Failed to load images.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchImages();
  }, []);

  if (isLoading) {
    return <div className="text-center text-gray-500">Loading images...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="relative w-full overflow-hidden p-4">
      <div className="flex space-x-4 animate-move">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.url}
            alt={`Promo ${index + 1}`}
            className="w-32 h-32 object-cover rounded-lg shadow-lg"
          />
        ))}
      </div>
    </div>
  );
};

export default ImageTicker;

