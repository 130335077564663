import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserHeader from '../components/UserHeader';
import LiveTopCash from '../components/LiveTopCash';
import LiveTopTransaction from '../components/LiveTopTransaction';
import ExchangeRateTable from '../components/ExchangeRateTable';
import CurrencyButtons from '../components/CurrencyButtons';
import ImageTicker from '../components/ImageTicker';
import Ticker from '../components/Ticker';

const UserDashboard = () => {
  const [currencyId, setCurrencyId] = useState(1);
  const { t, i18n } = useTranslation();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  console.log("API", API_BASE_URL)

  const handleCurrencyChange = (id) => {
    setCurrencyId(id);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <UserHeader />
      <div className="sticky top-0 left-0 w-full bg-navy-light p-4 shadow-md z-20 flex flex-wrap justify-between items-center">
        <p className="text-white text-xl font-semibold">{t("title")}</p>
        <div className="flex flex-wrap justify-end items-center mt-2 md:mt-0">
          <CurrencyButtons onCurrencyChange={handleCurrencyChange} />
          <select
            onChange={(e) => changeLanguage(e.target.value)}
            className="bg-white text-black rounded p-2 ml-4 mt-2 md:mt-0 md:ml-4"
            style={{ minWidth: '120px' }}
          >
            <option value="en">English</option>
            <option value="am">Amharic</option>
            <option value="sm">Somali</option>
            <option value="afan">Afan Oromo</option>
            <option value="tig">Tigrinya</option>
          </select>
        </div>
      </div>
      <Ticker />
      <main className="flex-grow p-4 grid grid-cols-1 md:grid-cols-2 gap-6">
        <LiveTopTransaction
          title={t("title_tsp")}
          endpoint={`${API_BASE_URL}/user/xlist/tra_tsp?currency_id=${currencyId}`}
          priceField="transactional_selling_price"
        />
        <LiveTopTransaction
          title={t("title_tbp")}
          endpoint={`${API_BASE_URL}/user/xlist/tra_tbp?currency_id=${currencyId}`}
          priceField="transactional_buying_price"
        />
        <LiveTopCash
          title={t("title_csp")}
          endpoint={`${API_BASE_URL}/user/xlist/cas_tsp?currency_id=${currencyId}`}
          priceField="cash_selling_price"
        />
        <LiveTopCash
          title={t("title_cbp")}
          endpoint={`${API_BASE_URL}/user/xlist/cas_tbp?currency_id=${currencyId}`}
          priceField="cash_buying_price"
        />
        <div className="col-span-1 md:col-span-2">
          <ImageTicker />
          <ExchangeRateTable defaultCurrencyId={currencyId} />
        </div>
      </main>
    </div>
  );
};

export default UserDashboard;

