import React from 'react';

const Sidebar = ({ onSectionChange }) => (
  <aside className="w-1/4 bg-gray-800 text-white p-4">
    <h2 className="text-xl">Sidebar</h2>
    <ul>
      <li><a href="#section1" className="hover:underline" onClick={() => onSectionChange('section1')}>Exchange List</a></li>
      <li><a href="#section2" className="hover:underline" onClick={() => onSectionChange('section2')}>Currency List</a></li>
      <li><a href="#section3" className="hover:underline" onClick={() => onSectionChange('section3')}>Banks List</a></li>
          <li><a href="#section4" className="hover:underline" onClick={() => onSectionChange('section4')}>Images</a></li>
      <li><a href="#section5" className="hover:underline" onClick={() => onSectionChange('section5')}>Articles</a></li> {/* New Article Section */}
    </ul>
  </aside>
);

export default Sidebar;

