import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddCurrencyModal from './AddCurrencyModal';

const CurrencyList = () => {
  const [currencies, setCurrencies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newCurrencyName, setNewCurrencyName] = useState('');
  const [newCurrencyIcon, setNewCurrencyIcon] = useState('');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem('exrToken');

  // Fetch the list of currencies from the backend
  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/admin/clist`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request headers
          },
        });
        setCurrencies(response.data); // Assuming the response contains an array of currencies
      } catch (error) {
        console.error('Error fetching currencies:', error);
      }
    };

    fetchCurrencies();
  }, [token]);

  // Handle currency deletion
  const handleDelete = async (currencyId) => {
    try {
      await axios.delete(`${API_BASE_URL}/admin/clist?currency_id=${currencyId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request headers
        },
      });
      setCurrencies(currencies.filter((currency) => currency.id !== currencyId));
    } catch (error) {
      console.error('Error deleting currency:', error);
    }
  };

  // Handle adding a new currency
  const handleAddCurrency = async () => {
    if (newCurrencyName.trim()) {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/admin/add_currency`,
          {
            currency_name: newCurrencyName,
            currency_icon: newCurrencyIcon,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request headers
            },
          }
        );

        setCurrencies([...currencies, response.data]); // Assuming the response contains the newly added currency's details
        setNewCurrencyName('');
        setNewCurrencyIcon('');
        setShowModal(false);
      } catch (error) {
        console.error('Error adding currency:', error);
      }
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Currency List</h2>
      <ul>
        {currencies.map((currency, index) => (
          <li key={index} className="flex justify-between items-center mb-2">
            <span>{currency.currency_name}</span>
            <span>{currency.currency_icon}</span>
            <button
              onClick={() => handleDelete(currency.id)}
              className="text-red-500 hover:text-red-700"
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
      <button
        onClick={() => setShowModal(true)}
        className="mt-4 bg-navy-800 text-white px-4 py-2 rounded hover:bg-navy-600"
      >
        Add Currency
      </button>

      {showModal && (
        <AddCurrencyModal
          newCurrencyName={newCurrencyName}
          setNewCurrencyName={setNewCurrencyName}
          newCurrencyIcon={newCurrencyIcon}
          setNewCurrencyIcon={setNewCurrencyIcon}
          handleAddCurrency={handleAddCurrency}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
};

export default CurrencyList;

