import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddExchangeModal = ({
  token,  // Receive the token prop
  handleAddExchangeRate,
  setShowModal
}) => {
  const [banks, setBanks] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [selectedBank, setSelectedBank] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [transactionalBuyingPrice, setTransactionalBuyingPrice] = useState('');
  const [transactionalSellingPrice, setTransactionalSellingPrice] = useState('');
  const [cashBuyingPrice, setCashBuyingPrice] = useState('');
  const [cashSellingPrice, setCashSellingPrice] = useState('');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    // Fetch the list of banks
    const fetchBanks = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/admin/blist`, {
          headers: {
            Authorization: `Bearer ${token}`,  // Include the token in the request headers
          },
        });
        setBanks(response.data); // Assuming the response contains an array of banks
      } catch (error) {
        console.error('Error fetching banks:', error);
      }
    };

    // Fetch the list of currencies
    const fetchCurrencies = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/admin/clist`, {
          headers: {
            Authorization: `Bearer ${token}`,  // Include the token in the request headers
          },
        });
        setCurrencies(response.data); // Assuming the response contains an array of currencies
      } catch (error) {
        console.error('Error fetching currencies:', error);
      }
    };

    fetchBanks();
    fetchCurrencies();
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddExchangeRate(selectedBank, selectedCurrency, transactionalBuyingPrice, transactionalSellingPrice, cashBuyingPrice, cashSellingPrice);
    resetForm();
  };

  const handleCancel = () => {
    resetForm();
    setShowModal(false);
  };

  const resetForm = () => {
    setSelectedBank('');
    setSelectedCurrency('');
    setTransactionalBuyingPrice('');
    setTransactionalSellingPrice('');
    setCashBuyingPrice('');
    setCashSellingPrice('');
  };

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center"
      onClick={() => handleCancel()}
    >
      <div
        className="bg-white rounded-lg shadow-md p-6 max-w-sm w-full"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="text-xl font-semibold mb-4">Add Exchange Rate</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="bank" className="block text-sm font-medium text-gray-700">
              Bank
            </label>
            <select
              id="bank"
              value={selectedBank}
              onChange={(e) => setSelectedBank(e.target.value)}
              className="mt-1 block w-full border-gray-300 bg-white text-gray-700 rounded-md shadow-sm focus:border-navy-500 focus:ring focus:ring-navy-500 focus:ring-opacity-50"
              required
            >
              <option value="">Select a bank</option>
              {banks.map((bank) => (
                <option key={bank.id} value={bank.id}>
                  {bank.bank_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
              Currency
            </label>
            <select
              id="currency"
              value={selectedCurrency}
              onChange={(e) => setSelectedCurrency(e.target.value)}
              className="mt-1 block w-full border-gray-300 bg-white text-gray-700 rounded-md shadow-sm focus:border-navy-500 focus:ring focus:ring-navy-500 focus:ring-opacity-50"
              required
            >
              <option value="">Select a currency</option>
              {currencies.map((currency) => (
                <option key={currency.id} value={currency.id}>
                  {currency.currency_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="transactionalBuyingPrice" className="block text-sm font-medium text-gray-700">
              Transactional Buying Price
            </label>
            <input
              type="number"
              id="transactionalBuyingPrice"
              value={transactionalBuyingPrice}
              onChange={(e) => setTransactionalBuyingPrice(e.target.value)}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="transactionalSellingPrice" className="block text-sm font-medium text-gray-700">
              Transactional Selling Price
            </label>
            <input
              type="number"
              id="transactionalSellingPrice"
              value={transactionalSellingPrice}
              onChange={(e) => setTransactionalSellingPrice(e.target.value)}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="cashBuyingPrice" className="block text-sm font-medium text-gray-700">
              Cash Buying Price
            </label>
            <input
              type="number"
              id="cashBuyingPrice"
              value={cashBuyingPrice}
              onChange={(e) => setCashBuyingPrice(e.target.value)}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="cashSellingPrice" className="block text-sm font-medium text-gray-700">
              Cash Selling Price
            </label>
            <input
              type="number"
              id="cashSellingPrice"
              value={cashSellingPrice}
              onChange={(e) => setCashSellingPrice(e.target.value)}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-navy-800 text-white px-4 py-2 rounded hover:bg-navy-600"
            >
              Add
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="text-gray-500 hover:text-gray-700"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddExchangeModal;

