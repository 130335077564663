import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import AdminHeaders from '../components/AdminHeaders';
import Sidebar from '../components/Sidebar';
import BankList from '../components/BankList';
import CurrencyList from '../components/CurrencyList';
import ExchangeRateList from '../components/ExchangeRateList';
import ImagePage from '../components/ImagePage';
import ArticleList from '../components/ArticleList';

const AdminDashboardProtected = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const [activeSection, setActiveSection] = useState('section1'); // State to track the active section

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/admin" />;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <AdminHeaders />
      <div className="flex">
        <Sidebar onSectionChange={setActiveSection} />
        <main className="flex-grow p-4">
          {activeSection === 'section1' && <ExchangeRateList/>}
          {activeSection === 'section2' && <CurrencyList/>}
          {activeSection === 'section3' && <BankList />}
          {activeSection === 'section4' && <ImagePage/>}
          {activeSection === 'section5' && <ArticleList/>}
        </main>
      </div>
    </div>
  );
};

export default AdminDashboardProtected;

