// src/components/AddArticleCategoryForm.jsx

import React, { useState } from 'react';
import axios from 'axios';

const AddArticleCategoryForm = ({ onClose }) => {
  const [newCategory, setNewCategory] = useState({ article_category_name: '', description: '' });
  const handleSubmit = async (e) => {
    e.preventDefault();
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem('exrToken');

    const { article_category_name } = newCategory; // Extract article_category_name

    try {
      await axios.post(
        `${API_BASE_URL}/admin/news_cat?article_category_name=${encodeURIComponent(article_category_name)}`,
        {}, // The request body is empty, as in your curl example
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setNewCategory({ article_category_name: '', description: '' });
      onClose(); // Close the modal
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-[#001f3f]">Add New Category</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Category Name
          </label>
          <input
            id="article_category_name"
            type="text"
            value={newCategory.article_category_name}
            onChange={(e) => setNewCategory({ ...newCategory, article_category_name: e.target.value })}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            placeholder="Enter category name"
          />
        </div>
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            Description (Optional)
          </label>
          <textarea
            id="description"
            value={newCategory.description}
            onChange={(e) => setNewCategory({ ...newCategory, description: e.target.value })}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            placeholder="Enter category description"
            rows={4}
          />
        </div>
        <button
          type="submit"
          className="w-full bg-[#001f3f] hover:bg-[#003366] text-white py-2 px-4 rounded-md flex items-center justify-center"
        >
          Add Category
        </button>
      </form>
    </div>
  );
};

export default AddArticleCategoryForm;

