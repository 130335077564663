import React, { useState } from 'react';
import axios from 'axios';

const ImageUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem('exrToken')

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      setFeedbackMessage('');
    } else {
      setFeedbackMessage('Please select a valid image file.');
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setFeedbackMessage('No file selected.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(
        `${API_BASE_URL}/admin/upload-image`,
        formData,
        {
          headers: {
              Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
        }
      );

      if (response.data.success) {
        setFeedbackMessage('Image uploaded successfully!');
      } else {
        setFeedbackMessage('Image upload failed.');
      }
    } catch (error) {
      setFeedbackMessage(`Upload error: ${error.response?.data?.detail || error.message}`);
    } finally {
      setUploadProgress(0);
      setSelectedFile(null);
      setPreviewUrl(null);
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Upload Promotional Image</h2>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        className="mb-4"
      />
      {previewUrl && (
        <div className="mb-4">
          <img src={previewUrl} alt="Selected" className="w-full h-auto rounded-lg" />
        </div>
      )}
      {uploadProgress > 0 && (
        <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
          <div
            className="bg-blue-600 h-2 rounded-full"
            style={{ width: `${uploadProgress}%` }}
          ></div>
        </div>
      )}
      <button
        onClick={handleUpload}
        className="bg-navy-800 text-white px-4 py-2 rounded hover:bg-navy-600"
      >
        Upload Image
      </button>
      {feedbackMessage && (
        <div className="mt-4 text-red-500">
          {feedbackMessage}
        </div>
      )}
    </div>
  );
};

export default ImageUpload;

