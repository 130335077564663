// AdminContext.jsx
import React, { createContext, useReducer, useEffect, useMemo } from "react";
import axios from "axios";

// Define the initial state and reducer
const initialState = {
  token: localStorage.getItem("exrToken") || null,
  error: null,
};

const adminReducer = (state, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return { ...state, token: action.payload, error: null };
    case "CLEAR_TOKEN":
      return { ...state, token: null, error: null };
    case "SET_ERROR":
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export const AdminContext = createContext();

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// Configure axios instance with base URL
const api = axios.create({
  baseURL: `${API_BASE_URL}`, // Use the correct base URL for your API
});

const useAdminFetch = (token, dispatch) => {
  useEffect(() => {
    const fetchAdmin = async () => {
      if (!token) return;

      try {
        const response = await api.get("/admin/me", { // Use the axios instance
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status !== 200) {
          dispatch({ type: "CLEAR_TOKEN" });
          localStorage.removeItem("exrToken");
        }
      } catch (error) {
        dispatch({ type: "SET_ERROR", payload: error.message });
      }
    };

    fetchAdmin();
  }, [token, dispatch]);
};

export const AdminProvider = ({ children }) => {
  const [state, dispatch] = useReducer(adminReducer, initialState);
    
  // Custom hook for fetching admin data
  useAdminFetch(state.token, dispatch);
    console.log("State",state)

  // Sync token with localStorage
  useEffect(() => {
    if (state.token) {
      localStorage.setItem("exrToken", state.token);
    } else {
      localStorage.removeItem("exrToken");
    }
  }, [state.token]);

  // Memoized context value to prevent unnecessary re-renders
  const setToken = useMemo(() => (token) => dispatch({ type: "SET_TOKEN", payload: token }), []);

  const contextValue = useMemo(() => [state.token, setToken], [state.token, setToken]);

  return (
    <AdminContext.Provider value={contextValue}>
      {console.log("Context Value",contextValue)}
      {children}
    </AdminContext.Provider>
  );
};

