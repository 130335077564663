import React from 'react';

const AddCurrencyModal = ({
  newCurrencyName,
  setNewCurrencyName,
  newCurrencyIcon,
  setNewCurrencyIcon,
  handleAddCurrency,
  setShowModal
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddCurrency(); // Call the parent function to add the currency
  };

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center"
      onClick={() => setShowModal(false)}
    >
      <div
        className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full"
        onClick={(e) => e.stopPropagation()} // Prevent click event from closing the modal
      >
        <h2 className="text-2xl font-semibold mb-4">Add Currency</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="currencyName">
              Currency Name
            </label>
            <input
              id="currencyName"
              type="text"
              value={newCurrencyName}
              onChange={(e) => setNewCurrencyName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="currencyIcon">
              Currency Icon (URL)
            </label>
            <input
              id="currencyIcon"
              type="text"
              value={newCurrencyIcon}
              onChange={(e) => setNewCurrencyIcon(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => setShowModal(false)}
              className="mr-4 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-navy-800 text-white px-4 py-2 rounded hover:bg-navy-600"
            >
              Add Currency
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCurrencyModal;

