import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AdminContext } from '../context/AdminContext'; // Adjust the path as needed
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [, setToken] = useContext(AdminContext); // Assuming AdminContext provides setToken
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_BASE_URL}/token`, new URLSearchParams({
        username,
        password,
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      const { access_token } = response.data;

      if (access_token) {
        // Set token in context and localStorage
        setToken(access_token);
        localStorage.setItem('exrToken', access_token);

        // Redirect to protected route
        navigate('/protected'); // Ensure '/protected' is the correct route
      }
    } catch (error) {
      setError(error.response?.data?.detail || error.message || 'An unexpected error occurred');
    }
  };

  return (
    <div className="min-h-screen flex justify-center items-center bg-navy">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-sm">
        <h2 className="text-2xl font-bold mb-4">Login</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="mb-4 p-2 w-full border rounded"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mb-4 p-2 w-full border rounded"
          />
          <button
            type="submit"
            className="bg-navy text-white py-2 px-4 rounded w-full"
          >
            Login
          </button>
          {error && <p className="text-red-500 mt-4">{typeof error === 'string' ? error : 'An error occurred'}</p>}
        </form>
      </div>
    </div>
  );
};

export default Login;

