import React, { useState } from 'react';

const CurrencyButtons = ({ onCurrencyChange }) => {
  const [activeId, setActiveId] = useState(null);

  const handleClick = (currencyId) => {
    onCurrencyChange(currencyId);
    setActiveId(currencyId);
  };

  const getButtonClass = (currencyId) => (
    `px-4 py-2 rounded-md shadow-md text-white text-sm md:text-base ${activeId === currencyId ? 'bg-navy-900' : 'bg-navy-700'} hover:bg-navy-800 focus:outline-none focus:ring-2 focus:ring-navy-500`
  );

  return (
    <div className="flex space-x-2">
      <button
        className={getButtonClass(10)}
        onClick={() => handleClick(10)}
      >
        $
      </button>
      <button
        className={getButtonClass(11)}
        onClick={() => handleClick(11)}
      >
        £
      </button>
      <button
        className={getButtonClass(3)}
        onClick={() => handleClick(3)}
      >
        AED
      </button>
      <button
        className={getButtonClass(4)}
        onClick={() => handleClick(4)}
      >
        SAR
      </button>
      <button
        className={getButtonClass(5)}
        onClick={() => handleClick(5)}
      >
        ¥
      </button>
    </div>
  );
};

export default CurrencyButtons;

