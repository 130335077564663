import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from "react-i18next";

const ExchangeRateTable = ({ defaultCurrencyId }) => {
  const [currencyList, setCurrencyList] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrencyId || '');
  const [exchangeRates, setExchangeRates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const {t} = useTranslation();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    // Fetch the currency list for the dropdown
    const fetchCurrencyList = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/user/clist`);
        setCurrencyList(response.data);
        if (!selectedCurrency) {
          setSelectedCurrency(response.data[0]?.id || '');
        }
      } catch (err) {
        console.error('Failed to fetch currency list:', err);
      }
    };

    fetchCurrencyList();
  }, [selectedCurrency]);

  useEffect(() => {
    // Fetch the exchange rates based on the selected currency
    const fetchExchangeRates = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/user/xlist`, {
          params: { currency_id: selectedCurrency },
        });
        setExchangeRates(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (selectedCurrency) {
      fetchExchangeRates();
    }
  }, [selectedCurrency]);

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="w-full max-w-full px-4 py-6 bg-white shadow-md rounded-lg">
      <h2 className="text-xl font-semibold text-center mb-6">
        {t("title_two")}: {moment(currentDate).format("MMMM Do, YYYY")}
      </h2>

      <div className="mb-4 text-center">
        <label htmlFor="currency" className="mr-2 text-sm font-medium">{t("currency_selection")}:</label>
        <select
          id="currency"
          value={selectedCurrency}
          onChange={handleCurrencyChange}
          className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {currencyList.map(currency => (
            <option key={currency.id} value={currency.id}>
              {currency.currency_name} ({currency.currency_icon})
              {console.log("Currencies", currency.id)}
            </option>
          ))}
        </select>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200 text-left text-sm font-medium text-gray-700">
              <th className="px-4 py-2 border-b border-gray-300">{t("bank")}</th>
              <th className="px-4 py-2 border-b border-gray-300">{t("tsp")}</th>
              <th className="px-4 py-2 border-b border-gray-300">{t("tbp")}</th>
              <th className="px-4 py-2 border-b border-gray-300">{t("csp")}</th>
              <th className="px-4 py-2 border-b border-gray-300">{t("cbp")}</th>
            </tr>
          </thead>
          <tbody>
            {exchangeRates.map((rate, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="px-4 py-2 border-b border-gray-300 whitespace-nowrap truncate">{rate.bank_name}</td>
                <td className="px-4 py-2 border-b border-gray-300">{rate.transactional_selling_price}</td>
                <td className="px-4 py-2 border-b border-gray-300">{rate.transactional_buying_price}</td>
                <td className="px-4 py-2 border-b border-gray-300">{rate.cash_selling_price}</td>
                <td className="px-4 py-2 border-b border-gray-300">{rate.cash_buying_price}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExchangeRateTable;

