import React from 'react';
import UserHeader from '../components/UserHeader';
import ComingSoon from '../components/ComingSoon';

const UserCommodities = () => {
  return (
    <div>
      <UserHeader />
      <ComingSoon />
    </div>

  )
}

export default UserCommodities;
