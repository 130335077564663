import React, { useState } from 'react';
import Login from '../components/Login';
import Register from '../components/Register';

const AdminDashboard = () => {
  const [view, setView] = useState('login'); // 'login' or 'register'

  return (
    <div className="min-h-screen bg-white">
      <div className="flex justify-center p-4">
        <button 
          onClick={() => setView('login')} 
          className={`mr-4 ${view === 'login' ? 'text-navy' : 'text-gray-400'}`}
        >
          Login
        </button>
        <button 
          onClick={() => setView('register')} 
          className={`${view === 'register' ? 'text-navy' : 'text-gray-400'}`}
        >
          Register
        </button>
      </div>
      {view === 'login' ? <Login /> : <Register />}
    </div>
  );
};

export default AdminDashboard;

